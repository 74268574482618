import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import NavigateButton from "./ActionsButton/NavigateButton";
import LeanModalButton from "./ActionsButton/LeanModalButton";
import OpenInventory from "./ActionsButton/OpenInventoryButton";
import { useTranslation } from "react-i18next";

const RowActions = React.memo(({ inventory }) => {
  console.log(inventory);
  const { t } = useTranslation();
  inventory.applicationType = "Inventory";

  return (
    <Col xs="6" md="6">
      <Row xs="auto" className="justify-content-center">
        <Col>
          <NavigateButton application={inventory} path="/scope">
            {t("dimensions")}
          </NavigateButton>
        </Col>
        <Col>
          <NavigateButton application={inventory} path="/reorder">
            {t("sortBy")}
          </NavigateButton>
        </Col>
        <Col>
          <NavigateButton application={inventory} path="/match" disabled={!inventory.testImage}>
            {t("match")}
          </NavigateButton>
        </Col>
        <Col>
          <LeanModalButton inventory={inventory} />
        </Col>
        <Col>
          <NavigateButton application={inventory} path="/info">
            {t("applicationInfo")}
          </NavigateButton>
        </Col>
        <Col>
          <NavigateButton application={inventory} path="/scheme">
            {t("scheme")}
          </NavigateButton>
        </Col>
        <Col>
          <NavigateButton application={inventory} path="/answers">
            {t("answers")}
          </NavigateButton>
        </Col>
        <Col>
          <OpenInventory application={inventory} />
        </Col>
      </Row>
    </Col>
  );
});

export default RowActions;
