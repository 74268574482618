import axios from "axios";

export const baseURL = "https://api.cus-pro.com/";
// export const baseURL = "https://api2.cus-pro.com/";
// export const baseURL = "https://localhost:44359/";

export const BaseApiUrl = baseURL + "api/";

export const postAsync = async (url, data, afterCallback, token) => {
  makeRequest("post", BaseApiUrl + url, null, data, afterCallback, token);
};

export const getAsync = async (url, param, afterCallback, token) => {
  makeRequest("get", BaseApiUrl + url, param, null, afterCallback, token);
};

const makeRequest = async (method, url, param, data, afterCallback, token) => {
  let response = null;
  try {
    response = await axios({
      method,
      url,
      data: data,
      params: param,
      // "Content-Type": "multipart/form-data",
      // enctype: "multipart/form-data",
      // responseType: "json",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
    if (afterCallback) afterCallback(response);
  } catch (err) {
    if (err.response?.status === 401) {
      localStorage.clear();
      window.location.reload(true);
    }
    // if (afterCallback) afterCallback(err);
  }
};

const xmlPostFormData = async (url, formData, token, callback) => {
  const request = new XMLHttpRequest();
  request.open("POST", BaseApiUrl + url);
  request.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      if (callback) callback(this);
    }
  };
  request.setRequestHeader("Authorization", `Bearer ${token}`);
  request.send(formData);
};

const ApiHelper = {
  postAsync,
  getAsync,
  xmlPostFormData,
  baseURL,
};

export default ApiHelper;
